import React from 'react';
import { lazy, useEffect, Suspense } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {loadUser} from './action/userAction';
import store from './store';
import PrivateRoute from './components/Route/private';

import { Navbar } from './components/layout/Navbar/navbars';
import Footer from './components/layout/Footer/Footer';
import  Loader  from "./components/layout/Loader/Loader";
const Home  = lazy(() => import('./components/Allcomponet/Home/home'));
const  ContactUsPage3 = lazy(() => import('./components/Allcomponet/Contact_us_page/cp3'));
const AdminPanel = lazy(() => import('./components/Admin/Dashboard/AdminPanel'));
const InquiryPage = lazy(() => import('./components/Allcomponet/Inqyiry_Page/InquiryPage'));
const DetailedPage  = lazy(() => import('./components/Allcomponet/Product/Product_Details/DetailedPage'));
const FAQPage1 = lazy(() => import('./components/Allcomponet/Faq_page/faq1'));
const AboutComponent = lazy(() => import('./components/Allcomponet/aboute_us/aboutpage'));
const ProductList = lazy(() => import('./components/Admin/Product/ProductList'));
const ProductForm = lazy(() => import('./components/Admin/Product/ProductCreateForm'));
const EditProduct = lazy(() => import('./components/Admin/Product/EditProduct'));
const OrganizationList = lazy(() => import('./components/Admin/Organization/OrganizationList'));
const  OrganizationForm= lazy(() => import('./components/Admin/Organization/OrganizationForm'));
const OrganizationEdit = lazy(() => import('./components/Admin/Organization/organizationEdit'));
const InquiryList = lazy(() => import('./components/Admin/Inquiry/InquiryList'));
const InquiryForm = lazy(() => import('./components/Admin/Inquiry/InquiryForm'));
const HeroList = lazy(() => import('./components/Admin/hero_session/HeroList'));
const HeroForm = lazy(() => import('./components/Admin/hero_session/HeroForm'));
const FAQList = lazy(() => import('./components/Admin/Faq/FAQList'));
const FAQForm  = lazy(() => import('./components/Admin/Faq/FAQForm'));
const FAQUpdateForm = lazy(() => import('./components/Admin/Faq/FAQUpdateForm'));
const Contectlist = lazy(() => import('./components/Admin/Contact_Us/contect_list'));
const AboutCeate = lazy(() => import('./components/Admin/aboutus/aboutcreate'));
const Aboutpost = lazy(() => import('./components/Admin/aboutus/about_post'));
const Aboutlist = lazy(() => import('./components/Admin/aboutus/aboutlist'));

const WhatsAppButton = lazy(() => import('./components/Allcomponet/wp/WhatsAppButton'));
const  Login  = lazy(() => import('./components/Admin/Login/Login'));


function App() {
  useEffect(() => {

    store.dispatch(loadUser());
  }, []);
  return (
    <BrowserRouter>
     <Suspense fallback={<Loader/>}>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id" element={<DetailedPage />} />
          <Route path="/contact" element={<ContactUsPage3 />} />
          <Route path="/inq" element={<InquiryPage />} />
          <Route path="/faq" element={<FAQPage1 />} />
          <Route path="/abu" element={<AboutComponent />} />


          <Route path="/admin/dashboard" element={<PrivateRoute element={ <AdminPanel /> }  />} />
          <Route path="/admin/product" element={<PrivateRoute element={ <ProductList /> }  />} />
          <Route path="/admin/product/create" element={<PrivateRoute element={ <ProductForm /> }  />} />
          <Route path="/admin/product/edit/:id" element={<PrivateRoute element={ <EditProduct /> }  />} />
          <Route path="/admin/org" element={<PrivateRoute element={ <OrganizationList /> }  />} />
          <Route path="/admin/org/create" element={<PrivateRoute element={ <OrganizationForm /> }  />} />
          <Route path="/admin/org/:id" element={<PrivateRoute element={ <OrganizationEdit /> }  />} />
          <Route path="/admin/inq" element={<PrivateRoute element={ <InquiryList /> }  />} />
          <Route path="/admin/inq/create" element={<PrivateRoute element={ <InquiryForm /> }  />} />
          <Route path="/admin/her" element={<PrivateRoute element={ <HeroList /> }  />} />
          <Route path="/admin/her/create" element={<PrivateRoute element={ <HeroForm /> }  />} />
          <Route path="/admin/faq" element={<PrivateRoute element={ <FAQList /> }  />} />
          <Route path="/admin/faq/create" element={<PrivateRoute element={ <FAQForm /> }  />} />
          <Route path="/admin/faq/edit/:id" element={<PrivateRoute element={ <FAQUpdateForm /> }  />} />
          <Route path="/admin/contact" element={<PrivateRoute element={ <Contectlist /> }  />} />
          <Route path="/admin/about/create" element={<PrivateRoute element={ <AboutCeate /> }  />} />
          <Route path="/admin/about/:id" element={<PrivateRoute element={ <Aboutpost /> }  />} />
          <Route path="/admin/about" element={<PrivateRoute element={ <Aboutlist /> }  />} />
          <Route path="/admin/login" element={<Login/>} />

          


        </Routes>
        <WhatsAppButton/>
        <Footer/>
    </Suspense>
    
    
    </BrowserRouter>
    
    
  );
}

export default App;
