
export const ABOUT_REQUEST = 'ABOUT_REQUEST';
export const ABOUT_SUCCESS = 'ABOUT_SUCCESS';
export const ABOUT_FAILURE = 'ABOUT_FAILURE';

export const UPDATE_ABOUT_REQUEST = 'UPDATE_ABOUT_REQUEST';
export const UPDATE_ABOUT_SUCCESS = 'UPDATE_ABOUT_SUCCESS';
export const UPDATE_ABOUT_FAILURE = 'UPDATE_ABOUT_FAILURE';

export const SUBMIT_FORM_REQUEST = 'SUBMIT_FORM_REQUEST';
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE';

export const GET_ALL_QUOTATIONS_REQUEST = 'GET_ALL_QUOTATIONS_REQUEST';
export const GET_ALL_QUOTATIONS_SUCCESS = 'GET_ALL_QUOTATIONS_SUCCESS';
export const GET_ALL_QUOTATIONS_FAILURE = 'GET_ALL_QUOTATIONS_FAILURE';

export const GET_ONE_QUOTATION_REQUEST = 'GET_ONE_QUOTATION_REQUEST';
export const GET_ONE_QUOTATION_SUCCESS = 'GET_ONE_QUOTATION_SUCCESS';
export const GET_ONE_QUOTATION_FAILURE = 'GET_ONE_QUOTATION_FAILURE';

export const ACTIVATE_DOCUMENT_REQUEST = 'ACTIVATE_DOCUMENT_REQUEST';
export const ACTIVATE_DOCUMENT_SUCCESS = 'ACTIVATE_DOCUMENT_SUCCESS';
export const ACTIVATE_DOCUMENT_FAILURE = 'ACTIVATE_DOCUMENT_FAILURE';

export const FETCH_ABOUT_REQUEST = 'FETCH_ABOUT_REQUEST';
export const FETCH_ABOUT_SUCCESS = 'FETCH_ABOUT_SUCCESS';
export const FETCH_ABOUT_FAILURE = 'FETCH_ABOUT_FAILURE';

export const CLEAR_ERRORS = "CLEAR_ERRORS";