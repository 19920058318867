export const CREATE_INQUIRY_REQUEST = 'CREATE_INQUIRY_REQUEST';
export const CREATE_INQUIRY_SUCCESS = 'CREATE_INQUIRY_SUCCESS';
export const CREATE_INQUIRY_FAILURE = 'CREATE_INQUIRY_FAILURE';

export const GET_ALL_INQUIRIES_REQUEST = 'GET_ALL_INQUIRIES_REQUEST';
export const GET_ALL_INQUIRIES_SUCCESS = 'GET_ALL_INQUIRIES_SUCCESS';
export const GET_ALL_INQUIRIES_FAILURE = 'GET_ALL_INQUIRIES_FAILURE';

export const GET_INQUIRY_BY_ID_REQUEST = 'GET_INQUIRY_BY_ID_REQUEST';
export const GET_INQUIRY_BY_ID_SUCCESS = 'GET_INQUIRY_BY_ID_SUCCESS';
export const GET_INQUIRY_BY_ID_FAILURE = 'GET_INQUIRY_BY_ID_FAILURE';

export const DELETE_INQUIRY_REQUEST = 'DELETE_INQUIRY_REQUEST';
export const DELETE_INQUIRY_SUCCESS = 'DELETE_INQUIRY_SUCCESS';
export const DELETE_INQUIRY_FAILURE = 'DELETE_INQUIRY_FAILURE';

export const UPDATE_INQUIRY_REQUEST = 'UPDATE_INQUIRY_REQUEST';
export const UPDATE_INQUIRY_SUCCESS = 'UPDATE_INQUIRY_SUCCESS';
export const UPDATE_INQUIRY_FAILURE = 'UPDATE_INQUIRY_FAILURE';


export const CLEAR_ERRORS = "CLEAR_ERRORS";