import {ABOUT_SUCCESS,
    ABOUT_FAILURE,
    ABOUT_REQUEST,
    UPDATE_ABOUT_REQUEST,
    UPDATE_ABOUT_SUCCESS,
    UPDATE_ABOUT_FAILURE,
    ACTIVATE_DOCUMENT_REQUEST,
  ACTIVATE_DOCUMENT_SUCCESS,
  ACTIVATE_DOCUMENT_FAILURE,
    CLEAR_ERRORS,
    FETCH_ABOUT_FAILURE,
    FETCH_ABOUT_SUCCESS,
    FETCH_ABOUT_REQUEST
} from '../constants/aboutConstants';



const initialState = {
    aboutInfo: null,
    loading: false,
    error: null
  };
  
  export  const aboutReducer = (state = initialState, action) => {
    switch (action.type) {
      
        case UPDATE_ABOUT_REQUEST:
          case ACTIVATE_DOCUMENT_REQUEST:
            case FETCH_ABOUT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      
        case UPDATE_ABOUT_SUCCESS:
          case ACTIVATE_DOCUMENT_SUCCESS:
            case FETCH_ABOUT_SUCCESS:
        return {
          ...state,
          aboutInfo: action.payload,
          loading: false,
          error: null
        };
      
        case UPDATE_ABOUT_FAILURE:
          case ACTIVATE_DOCUMENT_FAILURE:
            case FETCH_ABOUT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
        case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };
 
  const initialStat = {
    aboutInfo: [], // Initialize as an empty array
    loading: false,
    error: null
  };
  
  // Redux reducer function to manage state updates
  export const aboutReducers = (state = initialStat, action) => {
    switch (action.type) {
      case ABOUT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case ABOUT_SUCCESS:
        return {
          ...state,
          loading: false,
          aboutInfo: action.payload, // Update aboutInfo with fetched data
          error: null
        };
      case ABOUT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload // Set error message on failure
        };
      default:
        return state;
    }
  };