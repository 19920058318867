import {
    CREATE_HERO_REQUEST,
    CREATE_HERO_SUCCESS,
    CREATE_HERO_FAILURE,
    DELETE_HERO_REQUEST,
    DELETE_HERO_SUCCESS,
    DELETE_HERO_FAILURE,
    UPDATE_HERO_REQUEST,
    UPDATE_HERO_SUCCESS,
    UPDATE_HERO_FAILURE,
    HERO_REQUEST,
HERO_SUCCESS,
HERO_FAILURE,
GET_HERO_REQUEST,
GET_HERO_SUCCESS,
GET_HERO_FAILURE,
GET_ALL_HEROES_REQUEST,
GET_ALL_HEROES_SUCCESS,
GET_ALL_HEROES_FAILURE
  } from '../constants/Heroconstants';
  
 
  
   const initialState = {
    loading: false,
    success: false,
    error: null,
    heroData: null
  };
  
 export const heroReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_HERO_REQUEST:
        case DELETE_HERO_REQUEST:
          case UPDATE_HERO_REQUEST:
            case HERO_REQUEST:
              case GET_HERO_REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          error: null
        };
  
      case CREATE_HERO_SUCCESS:
        case DELETE_HERO_SUCCESS:
          case UPDATE_HERO_SUCCESS:
            case HERO_SUCCESS:
              case GET_HERO_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          heroData: action.payload,
          error: null
        };
  
      case CREATE_HERO_FAILURE:
        case DELETE_HERO_FAILURE:
          case UPDATE_HERO_FAILURE:
            case HERO_FAILURE:
              case GET_HERO_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
          heroData: null
        };
  
      default:
        return state;
    }
  };

  const initialStates = {
    loading: false,
    heroes: [],
    error: ''
  };
  
  export const   heroReducers = (state = initialStates, action) => {
    switch (action.type) {
      case GET_ALL_HEROES_REQUEST:
        return {
          ...state,
          loading: true
        };
      case GET_ALL_HEROES_SUCCESS:
        return {
          ...state,
          loading: false,
          heroes: action.payload,
          error: ''
        };
      case GET_ALL_HEROES_FAILURE:
        return {
          ...state,
          loading: false,
          heroes: [],
          error: action.payload
        };
      default:
        return state;
    }
  };
  