import axios from 'axios';
import {
    CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  FETCH_ALL_ORGANIZATIONS_REQUEST,
  FETCH_ALL_ORGANIZATIONS_SUCCESS,
  FETCH_ALL_ORGANIZATIONS_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  FETCH_ONE_ORGANIZATION_REQUEST,
  FETCH_ONE_ORGANIZATION_SUCCESS,
  FETCH_ONE_ORGANIZATION_FAILURE,
  
} from '../constants/organizationConstants';

import {ACTIVATE_DOCUMENT_REQUEST,
  ACTIVATE_DOCUMENT_SUCCESS,
  ACTIVATE_DOCUMENT_FAILURE} from '../constants/aboutConstants'

export const createOrganization = (organizationData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ORGANIZATION_REQUEST
    });
    try {
      const {data} = await axios.post('/api/organizations', organizationData);
      dispatch({
        type: CREATE_ORGANIZATION_SUCCESS,
        payload: data.organization
      });
    } catch (error) {
      dispatch({
        type: CREATE_ORGANIZATION_FAILURE,
        payload: error.response.data.message
      });
    }
  };
};

  
  export const fetchAllOrganizations = () => {
    return async (dispatch) => {
      dispatch({
        type: FETCH_ALL_ORGANIZATIONS_REQUEST
      });
      try {
        const {data} = await axios.get('/api/orgs');
        dispatch({
          type: FETCH_ALL_ORGANIZATIONS_SUCCESS,
          payload: data.organizations
        });
      } catch (error) {
        dispatch({
          type: FETCH_ALL_ORGANIZATIONS_FAILURE,
          payload: error.response.data.message
        });
      }
    };
  };


  
  export const updateOrganization = (id, organizationData) => {
    return async (dispatch) => {
      dispatch({
        type: UPDATE_ORGANIZATION_REQUEST
      });
      try {
        const {data} = await axios.put(`/api/organizationst/${id}`, organizationData);
        console.log(id);
        dispatch({
          type: UPDATE_ORGANIZATION_SUCCESS,
          payload: data.organization
        });
      } catch (error) {
        dispatch({
          type: UPDATE_ORGANIZATION_FAILURE,
          payload: error.response.data.message
        });
      }
    };
  };

  
  export const deleteOrganization = (id) => {
    return async (dispatch) => {
      dispatch({
        type: DELETE_ORGANIZATION_REQUEST
      });
      try {
        await axios.delete(`/api/organizations/${id}`);
        dispatch({
          type: DELETE_ORGANIZATION_SUCCESS
        });
      } catch (error) {
        dispatch({
          type: DELETE_ORGANIZATION_FAILURE,
          payload: error.response.data.message
        });
      }
    };
  };

  
  export const fetchOneOrganization = (id) => {
    return async (dispatch) => {
      dispatch({
        type: FETCH_ONE_ORGANIZATION_REQUEST
      });
    
      try {
        const {data} = await axios.get(`/api/organizations/${id}`);
        dispatch({
          type: FETCH_ONE_ORGANIZATION_SUCCESS,
          payload: data.organization
        });
      } catch (error) {
        dispatch({
          type: FETCH_ONE_ORGANIZATION_FAILURE,
          payload: error.response.data.message
        });
      }
    };
  };

  export const activateDocuments = (id) => {
    return async (dispatch) => {
      dispatch({ type: ACTIVATE_DOCUMENT_REQUEST });
  
      try {
        const {data} = await axios.put(`/api/organizationss/${id}`);
        // const updatedAbout = data;
        
        dispatch({
          type: ACTIVATE_DOCUMENT_SUCCESS,
          payload: data
        });
      } catch (error) {
        dispatch({
          type: ACTIVATE_DOCUMENT_FAILURE,
          payload: error.response.data.message || 'Something went wrong'
        });
      }
    };
  };