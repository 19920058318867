import {
    CREATE_FAQ_REQUEST,
    CREATE_FAQ_SUCCESS,
    CREATE_FAQ_FAILURE,
    GET_FAQ_BY_ID_REQUEST,
    GET_FAQ_BY_ID_SUCCESS,
    GET_FAQ_BY_ID_FAILURE,
    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAILURE,
    UPDATE_FAQ_REQUEST,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAILURE,
  DELETE_FAQ_REQUEST,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILURE,
  CLEAR_ERRORS
  } from '../constants/faqConstants';
  
  const initialStates = {
    loading: false,
    error: null,
    faq: {}
  };
  
  export const faqReducer = (state = initialStates, action) => {
    switch (action.type) {
      case CREATE_FAQ_REQUEST:
      case GET_FAQ_BY_ID_REQUEST:
      case UPDATE_FAQ_REQUEST:
      case DELETE_FAQ_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case CREATE_FAQ_SUCCESS:
      case GET_FAQ_BY_ID_SUCCESS:
      case UPDATE_FAQ_SUCCESS:
        return {
          ...state,
          loading: false,
          faq: action.payload,
          error: null
        };
      case CREATE_FAQ_FAILURE:
      case GET_FAQ_BY_ID_FAILURE:
      case UPDATE_FAQ_FAILURE:
        return {
          ...state,
          loading: false,
          faq: null,
          error: action.payload
        };
      case DELETE_FAQ_SUCCESS:
          return {
            ...state,
            loading: false,
            error: null
          };
      case DELETE_FAQ_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload
          };
          case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };
  



  const initialStatew = {
    loading: false,
    error: null,
    faq: []
  };
  
  export const faqsReducer = (state = initialStatew, action) => {
    switch (action.type) {
      case GET_FAQ_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_FAQ_SUCCESS:
        return {
          ...state,
          loading: false,
          faq: action.payload,
          error: null
        };
      case GET_FAQ_FAILURE:
        return {
          ...state,
          loading: false,
          faq: [],
          error: action.payload
        };
        case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };

