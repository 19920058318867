import {
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAILURE,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAILURE,
    GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE
  } from '../constants/productConstants';
  
  const initialState = {
    loading: false,
    newProduct: {},
    error: null,
    success: false,
  };
  
 export const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case PRODUCT_CREATE_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
            case FETCH_PRODUCT_REQUEST:
                case PRODUCT_DELETE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
          success: false,
        };
      case PRODUCT_CREATE_SUCCESS:
        case UPDATE_PRODUCT_SUCCESS:
            case FETCH_PRODUCT_SUCCESS:
                case PRODUCT_DELETE_SUCCESS:
        return {
          ...state,
          loading: false,
          newProduct: action.payload,
          error: null,
          success: true,
        };
      case PRODUCT_CREATE_FAILURE:
        case UPDATE_PRODUCT_FAILURE:
            case FETCH_PRODUCT_FAILURE:
                case PRODUCT_DELETE_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };
      default:
        return state;
    }
  };

  const initialStates = {
    loading: false,
    products: [],
    error: null
  };
  
 export const productReducers = (state = initialStates, action) => {
    switch (action.type) {
      case GET_PRODUCTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_PRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          products: action.payload,
          error: null
        };
      case GET_PRODUCTS_FAILURE:
        return {
          ...state,
          loading: false,
          products: [],
          error: action.payload
        };
      default:
        return state;
    }
  };

  
