// Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h3>About Us</h3>
        <p>Laxmi Engineering Works is in this field since 1989 & has an experience of more than 2 decades.<br/> 
        <b>WHY PEOPLE CHOOSE US</b><br></br>Trust, Transparency, Ethical Business Policy, Customer Centric Approach, Best After Sales Services</p>
       
      </div>
      <div className="footer-column">
        <h3>Address</h3>
        E-39 Shayona industrial Estate <br />
           Memco, Ahmedabad ,380025 <br />
            India 
        <h3>Contact us</h3>
        <a  className= "number" href="tel:+919157899091">9157899091 </a>
        <a className='number' href="mailto:laxmieng0812@gmail.com">laxmieng0812@gmail.com</a>
      </div>
      <div className="footer-column">
        <iframe
          title="Map Location"
          width="100%"
          height="100"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Laxmi%20Engineering%20Works%20%7C%20Plastic%20Scrap%20Grinder%20Machine%20%7C%20Plastic%20Extruder%20Machine,%20E-39,%20Shayona%20Estate%20Nr,%20Memco%20Cross%20Road,%20Memco%20Bridge,%20Ahmedabad,%20Gujarat%20380023+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps tracker sport</a>
        </iframe>
      </div>
    </footer>
  );
};

export default Footer;
