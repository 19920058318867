import React, { useState,useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbars.css";
import { useDispatch, useSelector } from "react-redux";
import {fetchAllOrganizations} from '../../../action/organizationAction'



export const Navbar = () => {

  // const { services } = useSelector(state => state.fetchservices);
    const {products}=useSelector((state)=>state.allproduct)
    const {organizations}=useSelector(state => state.allorganization);
  const dispatch=useDispatch()
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
  
    
    dispatch(fetchAllOrganizations())
}, [dispatch]);
const handleNavLinkClick = () => {
  // Close the menu when a navigation link is clicked
  setMenuOpen(false);
  setDropdownOpen(false);
  window.scrollTo({ top: 0, behavior: "smooth" });
};
  return (
    <nav className="header" >
      <Link to="/" className="title">
      {organizations.map(organization => (
        organization.isActive && (
          
          organization.name
        )
      )
    )
  }
      </Link>
      <div className="menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""} id="menus">
      <li >
          <NavLink to="/" onClick={handleNavLinkClick}>Home</NavLink>
        </li>
        <li className="dropdown">
          <NavLink className="dropbtn" onClick={toggleDropdown}>
            Services
            <i className={dropdownOpen ? "fa fa-caret-up" : "fa fa-caret-down"}></i>
          </NavLink>
          <div className={dropdownOpen ? "dropdown-content open" : "dropdown-content"}>
          {products.map((service, index) => (
          <NavLink key={index} to={`/${service._id}`} onClick={handleNavLinkClick}>
            {service.name}
          </NavLink>
        ))}
          </div>
        </li>
        <li>
          <NavLink to="/abu" onClick={handleNavLinkClick}>About</NavLink>
        </li>
        <li>
          <NavLink to="/contact" onClick={handleNavLinkClick}>Contact</NavLink>
        </li>
        <li>
          <NavLink to="/faq" onClick={handleNavLinkClick}>Q&A</NavLink>
        </li>
        <li className="iNQW">
          <NavLink to="/inq"  onClick={handleNavLinkClick}>Inquiry</NavLink>
        </li>

        
      </ul>
    </nav>
  );
};
