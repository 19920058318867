import {
    CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  FETCH_ALL_ORGANIZATIONS_REQUEST,
  FETCH_ALL_ORGANIZATIONS_SUCCESS,
  FETCH_ALL_ORGANIZATIONS_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  FETCH_ONE_ORGANIZATION_REQUEST,
  FETCH_ONE_ORGANIZATION_SUCCESS,
  FETCH_ONE_ORGANIZATION_FAILURE,
  CLEAR_ERRORS
} from '../constants/organizationConstants'

const initialState = {
    loading: false,
    error: null,
    organization: null
  };
  
 export  const fetchOneOrganizationReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_ORGANIZATION_REQUEST:
      case UPDATE_ORGANIZATION_REQUEST:
      case DELETE_ORGANIZATION_REQUEST:
      case FETCH_ONE_ORGANIZATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case CREATE_ORGANIZATION_SUCCESS:
      case UPDATE_ORGANIZATION_SUCCESS:
      case FETCH_ONE_ORGANIZATION_SUCCESS:
        return {
          ...state,
          loading: false,
          organization: action.payload,
          error: null
        };
      case CREATE_ORGANIZATION_FAILURE:
      case UPDATE_ORGANIZATION_FAILURE:
      case FETCH_ONE_ORGANIZATION_FAILURE:
        return {
          ...state,
          loading: false,
          organization: null,
          error: action.payload
        };
      case DELETE_ORGANIZATION_SUCCESS:
          return {
            ...state,
            loading: false,
            error: null
          };
      case DELETE_ORGANIZATION_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload
          };
          case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };

  const initialStates = {
    loading: false,
    error: null,
    organizations: []
  };
  
  export const fetchAllOrganizationsReducer = (state = initialStates, action) => {
    switch (action.type) {
      case FETCH_ALL_ORGANIZATIONS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_ALL_ORGANIZATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          organizations: action.payload,
          error: null
        };
      case FETCH_ALL_ORGANIZATIONS_FAILURE:
        return {
          ...state,
          loading: false,
          organizations: [],
          error: action.payload
        };
        case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };


