export const CREATE_FAQ_REQUEST = 'CREATE_FAQ_REQUEST';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAILURE = 'CREATE_FAQ_FAILURE';

export const GET_FAQ_BY_ID_REQUEST = 'GET_FAQ_BY_ID_REQUEST';
export const GET_FAQ_BY_ID_SUCCESS = 'GET_FAQ_BY_ID_SUCCESS';
export const GET_FAQ_BY_ID_FAILURE = 'GET_FAQ_BY_ID_FAILURE';

export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE';

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAILURE = 'UPDATE_FAQ_FAILURE';

export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAILURE = 'DELETE_FAQ_FAILURE';

export const CLEAR_ERRORS = "CLEAR_ERRORS";