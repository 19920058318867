import {
    CREATE_INQUIRY_REQUEST,
    CREATE_INQUIRY_SUCCESS,
    CREATE_INQUIRY_FAILURE,
    GET_ALL_INQUIRIES_REQUEST,
    GET_ALL_INQUIRIES_SUCCESS,
    GET_ALL_INQUIRIES_FAILURE,
    GET_INQUIRY_BY_ID_REQUEST,
    GET_INQUIRY_BY_ID_SUCCESS,
    GET_INQUIRY_BY_ID_FAILURE,
    DELETE_INQUIRY_REQUEST,
    DELETE_INQUIRY_SUCCESS,
    DELETE_INQUIRY_FAILURE,
    UPDATE_INQUIRY_REQUEST,
  UPDATE_INQUIRY_SUCCESS,
  UPDATE_INQUIRY_FAILURE
  } from '../constants/InquiryConstants';
  
  const initialState = {
    loading: false,
    success: false,
    error: null,
    inquiry: null
  };
  
 export const inquiryReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_INQUIRY_REQUEST:
        case GET_INQUIRY_BY_ID_REQUEST:
          case DELETE_INQUIRY_REQUEST:
            case UPDATE_INQUIRY_REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          error: null
        };
      case CREATE_INQUIRY_SUCCESS:
        case GET_INQUIRY_BY_ID_SUCCESS:
          case DELETE_INQUIRY_SUCCESS:
            case UPDATE_INQUIRY_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: null,
          inquiry: action.payload
        };
      case CREATE_INQUIRY_FAILURE:
        case GET_INQUIRY_BY_ID_FAILURE:
          case DELETE_INQUIRY_FAILURE:
        case UPDATE_INQUIRY_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  

  const initialStates = {
    loading: false,
    inquiries: [],
    error: null
  };
  
 export const inquiriesReducers = (state = initialStates, action) => {
    switch (action.type) {
      case GET_ALL_INQUIRIES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_ALL_INQUIRIES_SUCCESS:
        return {
          ...state,
          loading: false,
          inquiries: action.payload,
          error: null
        };
      case GET_ALL_INQUIRIES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  

  

