// Hero Action Types
export const CREATE_HERO_REQUEST = 'CREATE_HERO_REQUEST';
export const CREATE_HERO_SUCCESS = 'CREATE_HERO_SUCCESS';
export const CREATE_HERO_FAILURE = 'CREATE_HERO_FAILURE';

export const DELETE_HERO_REQUEST = 'DELETE_HERO_REQUEST';
export const DELETE_HERO_SUCCESS = 'DELETE_HERO_SUCCESS';
export const DELETE_HERO_FAILURE = 'DELETE_HERO_FAILURE';

export const HERO_REQUEST = 'HERO_REQUEST';
export const HERO_SUCCESS = 'HERO_SUCCESS';
export const HERO_FAILURE = 'HERO_FAILURE';

export const UPDATE_HERO_REQUEST = 'UPDATE_HERO_REQUEST';
export const UPDATE_HERO_SUCCESS = 'UPDATE_HERO_SUCCESS';
export const UPDATE_HERO_FAILURE = 'UPDATE_HERO_FAILURE';

// Define constants for hero actions
export const GET_HERO_REQUEST = 'GET_HERO_REQUEST';
export const GET_HERO_SUCCESS = 'GET_HERO_SUCCESS';
export const GET_HERO_FAILURE = 'GET_HERO_FAILURE';

// Define constants for hero actions
export const GET_ALL_HEROES_REQUEST = 'GET_ALL_HEROES_REQUEST';
export const GET_ALL_HEROES_SUCCESS = 'GET_ALL_HEROES_SUCCESS';
export const GET_ALL_HEROES_FAILURE = 'GET_ALL_HEROES_FAILURE';



export const CLEAR_ERRORS = "CLEAR_ERRORS";