// Import necessary dependencies
import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';


import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from './reducers/userReducer';

import{aboutReducer,aboutReducers}from "./reducers/aboutReducer";

import {getonecontact,fetchContactsReducer} from './reducers/contectReducer';

import {faqReducer,faqsReducer} from './reducers/faqReducer';

import {heroReducer,heroReducers} from './reducers/HeroReducer';
import {fetchAllOrganizationsReducer,fetchOneOrganizationReducer} from './reducers/organizationReducer';

import {productReducer,productReducers} from './reducers/productReducer';

import {inquiryReducer,inquiriesReducers} from './reducers/InquiryReducers';

const reducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  aboutUS:aboutReducer,
  getallAbout:aboutReducers,
  getallcoutact:getonecontact,
  allcontact:fetchContactsReducer,
  allFaq:faqsReducer,
  faq:faqReducer,
  allHero:heroReducers,
  hero:heroReducer,
  allorganization: fetchAllOrganizationsReducer,
  oneorgnisation: fetchOneOrganizationReducer,
  allproduct:productReducers,
  oneproduct:productReducer,
  oneInqiry:inquiryReducer,
  alliInquiries:inquiriesReducers
});

// Define middleware array
const middleware = [thunk];

// Create Redux store with middleware applied
const store = createStore(
  reducer,
  applyMiddleware(...middleware)
);

// Export the Redux store
export default store;
