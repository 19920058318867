import {
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_FAILURE,
    FETCH_CONTACTS_REQUEST,
    FETCH_CONTACTS_SUCCESS,
    FETCH_CONTACTS_FAILURE,
    UPDATE_CONTACT_REQUEST,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_FAILURE,
  CLEAR_ERRORS
} from '../constants/contectConstants'

const initialStateq = {
    loading: false,
    error: null,
    contact: null
  };
  
  export const createContactReducer = (state = initialStateq, action) => {
    switch (action.type) {
      case CREATE_CONTACT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case CREATE_CONTACT_SUCCESS:
        return {
          ...state,
          loading: false,
          contact: action.payload,
          error: null
        };
      case CREATE_CONTACT_FAILURE:
        return {
          ...state,
          loading: false,
          contact: null,
          error: action.payload
        };
        case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };


  
const initialStates = {
    loading: false,
    error: null,
    contacts: []
  };
  
export  const fetchContactsReducer = (state = initialStates, action) => {
    switch (action.type) {
      case FETCH_CONTACTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_CONTACTS_SUCCESS:
        return {
          ...state,
          loading: false,
          contacts: action.payload,
          error: null
        };
      case FETCH_CONTACTS_FAILURE:
        return {
          ...state,
          loading: false,
          contacts: [],
          error: action.payload
        };
        case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
      default:
        return state;
    }
  };
  

  const initialState = {
    contact: null,
    loading: false,
    error: null
  };
  
  export const getonecontact = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CONTACT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case UPDATE_CONTACT_SUCCESS:
        return {
          ...state,
          loading: false,
          contact: action.payload,
          error: null
        };
      case UPDATE_CONTACT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };