export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

export const FETCH_ALL_ORGANIZATIONS_REQUEST = 'FETCH_ALL_ORGANIZATIONS_REQUEST';
export const FETCH_ALL_ORGANIZATIONS_SUCCESS = 'FETCH_ALL_ORGANIZATIONS_SUCCESS';
export const FETCH_ALL_ORGANIZATIONS_FAILURE = 'FETCH_ALL_ORGANIZATIONS_FAILURE';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';



export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';

export const FETCH_ONE_ORGANIZATION_REQUEST = 'FETCH_ONE_ORGANIZATION_REQUEST';
export const FETCH_ONE_ORGANIZATION_SUCCESS = 'FETCH_ONE_ORGANIZATION_SUCCESS';
export const FETCH_ONE_ORGANIZATION_FAILURE = 'FETCH_ONE_ORGANIZATION_FAILURE';

export const CLEAR_ERRORS = "CLEAR_ERRORS";