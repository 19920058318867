import React from 'react';
import './Loader.css'; // Import CSS for styling

const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-content">
            <div className="loader-spinner"></div>
            <p className="loader-text">Engaging Works...</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
